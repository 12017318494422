<template>
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-primary card-outline">
        <div class="card-header">
          <h2 class="card-title">Stundenliste ({{employee.first_name}} {{employee.last_name}})</h2>
          <div class="card-tools">
            <div class="row">
              <div class="col-sm-6"></div>
            </div>
            <button type="button" class="btn btn-primary btn-sm" @click="updateSalary" :disabled="salary.payed == 1" v-if="$auth.check('salaries.update')">Speichern</button>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
              <div class="col-md-6 text-left">
                  <!-- nextEmployee -->
                  <button class="btn btn-sm btn-primary" @click="previousEmployee()" v-if="previous">
                      <i class="fas fa-arrow-left"></i>
                      {{previous.first_name}} {{previous.last_name}}
                  </button>
              </div>
              <div class="col-md-6 text-right">
                  <!-- nextEmployee -->
                  <button class="btn btn-sm btn-primary" @click="nextEmployee()" v-if="next">
                      {{next.first_name}} {{next.last_name}}
                      <i class="fas fa-arrow-right"></i>
                  </button>
              </div>
          </div>
          <ul class="pagination pagination-month justify-content-center" v-if="months.length > 0">
            <li class="page-item" @click="previousMonth()"><a class="page-link" href="#">«</a></li>
            <li class="page-item" v-for="month in months" :key="month.name" :class="month.klasse" @click="setShowDate(month.month, month.year)">
              <a class="page-link" href="#">
                <p class="page-month">{{ month.name }}</p>
                <p class="page-year">{{ month.year }}</p>
              </a>
            </li>
            <li class="page-item" @click="nextMonth()"><a class="page-link" href="#">»</a></li>
          </ul>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="row">
                <!-- 

                  Stunden Vormonat

                -->
                <div class="col-sm-4">
                  <div class="info-box">
                    <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-fw fa-calendar-day"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Monatsanfang:</span>
                        <span class="info-box-number">
                        {{ Math.round(parseFloat(salary.previous_hours) * 100) / 100 }}
                        <small>h</small>
                    </span>
                    </div>
                  </div>
                </div>

                <!-- 

                  Ist Stunden

                -->
                <div class="col-sm-4">
                  <div class="info-box">
                    <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-fw fa-stopwatch"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Ist-Arbeitszeit:</span>
                        <span class="info-box-number">
                        {{ Math.round(parseFloat(total_time) * 100) / 100 }}
                        <small>h</small>
                    </span>
                    </div>
                  </div>
                </div>

                <!-- 

                  Gesamt stunden

                -->
                <div class="col-sm-4">
                  <div class="info-box">
                    <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-fw fa-stopwatch"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Gesamt-Arbeitszeit:</span>
                        <span class="info-box-number">
                        {{ Math.round((parseFloat(total_time) + parseFloat(salary.previous_hours)) * 100) / 100 }}
                        <small>h</small>
                    </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">   
                <div class="col-sm-4">
                  <div class="info-box">
                    <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-fw fa-business-time"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Soll-Arbeitszeit:</span>
                        <span class="info-box-number">
                        {{ salary.base_time }}
                        <small>h</small>
                    </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">

                </div>
                <div class="col-sm-4">
                  <div class="info-box">
                    <span class="info-box-icon bg-secondary elevation-1"><i class="fas fa-fw fa-money-bill"></i></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Ausbezahlte Stunden:</span>
                        <span class="info-box-number">
                        <template v-if="salary.payed == 1">
                          {{ salary.payed_hours }}
                          <small>h</small>
                        </template>
                        <template v-if="salary.payed == 0">
                          <input type="number" class="form-control form-control-sm" v-model="salary.payed_hours"/>
                        </template>
                    </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-sm-12" v-if="salary.payed == 0">
                  <button class="btn btn-danger btn-sm form-control" @click="closeMonth()" :disabled="salary.payed == 1" v-if="$auth.check('salaries.close')">Diesen Monat abrechnen</button>
                </div>
                <div class="col-sm-12" v-if="salary.payed == 1">
                  <button class="btn btn-success btn-sm form-control" @click="openMonth()" :disabled="salary.payed == 0" v-if="$auth.check('salaries.close')">Diesen Monat bearbeiten</button>
                </div>
              </div>

              
            </div>
            <div class="col-md-6">
              <div class="form-group">
                  <label for="comment" class="control-label col-form-label col-form-label-sm">Kommentar / Wünsche / Anregungen</label>
                  <textarea rows="5" class="form-control form-control-sm" name="comment" id="comment" v-model="salary.comment"></textarea>
              </div>
            </div>
          </div>
          <table class="table table-striped table-bordered table-sm">
            <thead style="position: sticky;top: 0;" class="thead-dark text-center">
              <th>Datum</th>
              <th>Tag</th>
              <th>Start</th>
              <th>Stop</th>
              <th>Pause</th>
              <th>Stunden</th>
              <th style="max-width: 1vw;">Urlaub</th>
              <th style="max-width: 1vw;">Urlaub <br>(Unbezahlt)</th>
              <th style="max-width: 1vw;">Krank</th>
              <th style="max-width: 1vw;">Krank <br>(Unbezahlt)</th>
              <th style="max-width: 1vw;">Schule <br>(1. Tag)</th>
              <th style="max-width: 1vw;">Schule <br>(2. Tag)</th>
              <th style="max-width: 1vw;">Feiertag</th>
            </thead>
            <tbody>
              <tr v-for="(day, index) in salary.times" :key="day.id" :class="generateColor(index)">
                <td>{{ day.date | formatDate }}</td>
                <td>{{ day.weekday }}</td>
                <td>
                  <input type="time" class="form-control form-control-sm" v-model="salary.times[index].start_time" :disabled="salary.payed == 1"/>
                    
                    <b-button class="p-0 pl-2 mt-2 text-left"  v-b-toggle='"collapse-" + index' variant="primary" style="width: 100%;">Zeit 2:</b-button>
                    <b-collapse :id="'collapse-' + index" class="mt-2" :visible="salary.times[index].second_start_time != '00:00' || salary.times[index].second_end_time != '00:00'" >
                      <input type="time" class="form-control form-control-sm" v-model="salary.times[index].second_start_time" :disabled="salary.payed == 1" @change="hasChanges = true"/>

                      Grund:
                      <input type="text" class="form-control form-control-sm" v-model="salary.times[index].second_reason" :disabled="salary.payed == 1" @change="hasChanges = true"/>
                    </b-collapse>
                </td>
                <td>
                  <input type="time" class="form-control form-control-sm" v-model="salary.times[index].end_time" :disabled="salary.payed == 1"/>
                    <b-button class="p-0 pl-2 mt-2 text-left"  v-b-toggle='"collapse-" + index' variant="primary" style="width: 100%;visibility: hidden;">Zeit 2:</b-button>
                    <b-collapse :id="'collapse-' + index" class="mt-2" :visible="salary.times[index].second_start_time != '00:00' || salary.times[index].second_end_time != '00:00'" >
                      <input type="time" class="form-control form-control-sm" v-model="salary.times[index].second_end_time" :disabled="salary.payed == 1" @change="hasChanges = true"/>
                    </b-collapse>
                </td>
                <td>
                    <br>
                    <span>{{ calculateBreak(index) }} h</span>
                    <br>
                    <b-collapse :id="'collapse-' + index" class="mt-2" :visible="salary.times[index].second_start_time != '00:00' || salary.times[index].second_end_time != '00:00'" >
                      <br><br><br>
                      {{ calculateBreak(index, true) }} h
                    </b-collapse>
                </td>
                <td>
                    <br>
                    <span>{{ calculateTime(index) }} h</span>
                    <br>
                    <b-collapse :id="'collapse-' + index" class="mt-2" :visible="salary.times[index].second_start_time != '00:00' || salary.times[index].second_end_time != '00:00'" >
                      <br><br><br>
                      {{ calculateTime(index, true) }} h
                    </b-collapse>
                  <!-- <span v-if="salary.times[index].start_time != null && salary.times[index].end_time != null">{{ (timeToDecimal(salary.times[index].end_time) - timeToDecimal(salary.times[index].start_time)) - calculateBreak(index) }} h</span>
                  <span v-else>0 h</span> -->
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchHoliday' + day.id" v-model="salary.times[index].holiday" :disabled="salary.times[index].holiday_unpaid == true ||  salary.times[index].ill == true||  salary.times[index].ill_unpaid == true||  salary.times[index].shool_short == true||  salary.times[index].shool_long == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchHoliday'+day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchHolidayUnpaid' + day.id" v-model="salary.times[index].holiday_unpaid" :disabled="salary.times[index].holiday == true || salary.times[index].ill == true || salary.times[index].ill_unpaid == true || salary.times[index].shool_short == true || salary.times[index].shool_long == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchHolidayUnpaid'+day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchIll' + day.id" v-model="salary.times[index].ill" :disabled="salary.times[index].holiday == true || salary.times[index].holiday_unpaid == true || salary.times[index].ill_unpaid == true || salary.times[index].shool_short == true || salary.times[index].shool_long == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchIll' + day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchIllUnpaid' + day.id" v-model="salary.times[index].ill_unpaid" :disabled="salary.times[index].holiday == true || salary.times[index].holiday_unpaid == true || salary.times[index].ill == true || salary.times[index].shool_short == true || salary.times[index].shool_long == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchIllUnpaid' + day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchShoolLong' + day.id" v-model="salary.times[index].shool_long" :disabled="salary.times[index].holiday == true || salary.times[index].holiday_unpaid == true || salary.times[index].ill == true || salary.times[index].ill_unpaid == true || salary.times[index].shool_short == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchShoolLong' + day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchShoolShort' + day.id" v-model="salary.times[index].shool_short" :disabled="salary.times[index].holiday == true || salary.times[index].holiday_unpaid == true || salary.times[index].ill == true || salary.times[index].ill_unpaid == true || salary.times[index].shool_long == true || salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchShoolShort' + day.id"></label>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitchLegalHoliday' + day.id" v-model="salary.times[index].legal_holiday" :disabled="salary.payed == 1">
                        <label class="custom-control-label" :for="'customSwitchLegalHoliday' + day.id"></label>
                      </div>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
    props: [
        'employee',
        'next',
        'previous'
    ],

    data() {
      return {
        currentDate: moment(),
        showDate: moment(),
        salary: {
          times: [],
          employee: {
            base_time: 0,
          }
        },
        months: [],
        total_time: 0,
      }
  },

  watch: {
        showDate: {
            handler () {
                this.getSalary();
                this.generateMonths();
            },
        },
        salary:{
            handler(){
              this.calculateTotalTime();
            },
            deep: true,
        },
        employee: {
            handler() {
                this.getSalary();
            },
            deep: true,
        }
    },

  methods: {
      nextEmployee()
      {
          this.$router.push({ name: 'employees.time-tracking', params: { id: this.next.id } })
          this.$parent.getEmployee();
      },
      previousEmployee()
      {
          this.$router.push({ name: 'employees.time-tracking', params: { id: this.previous.id } })
          this.$parent.getEmployee();
      },
      generateColor(i){
        let color = '';
        if(this.salary.times[i].weekday === "Sonntag"){
          color = 'table-warning';
        }

        if(this.salary.times[i].official_Holiday == true){
          color = 'table-danger';
        }

        return color;
      },
      previousMonth() {
        this.currentDate.subtract(1, 'year');
        this.generateMonths();
        this.getSalary();
      },
      nextMonth() {
        this.currentDate.add(1, 'year');
        this.generateMonths();
        this.getSalary();
      },

      closeMonth(){
        this.axios
        .put("/salary/" + this.salary.id, this.salary)
        .then(() => {
          this.axios
            .post("/salary/close", {
              'salary_id': this.salary.id,
              'payed_hours': this.salary.payed_hours,
              'next_hours': this.salary.previous_hours - this.salary.payed_hours +  this.total_time
            })
            .then(() => {
              // this.salary = response.data.data;
              // this.generateTable(month, year);
              this.getSalary();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
      },

      openMonth() {
        this.axios
        .put("/salary/" + this.salary.id, this.salary)
        .then(() => {
          this.axios
            .post("/salary/open", {
              'salary_id': this.salary.id,
            })
            .then(() => {
              this.getSalary();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
      },

      calculateTotalTime(){
        this.total_time = 0;
        this.salary.times.forEach(function(item, index){
          this.total_time += parseFloat(this.calculateTime(index));
        }, this);
        this.salary.times.forEach(function(item, index){
          this.total_time += parseFloat(this.calculateTime(index, true));
        }, this);
      },
      calculateTime(index, second = false){
        var time = 0;
        if(second == true)
        {
          if(this.salary.times[index].second_start_time != null && this.salary.times[index].second_start_time != "" && this.salary.times[index].second_end_time != null && this.salary.times[index].second_end_time != ""){
            time = parseFloat(time) + (this.timeToDecimal(this.salary.times[index].second_end_time) - this.timeToDecimal(this.salary.times[index].second_start_time)) - this.calculateBreak(index, true) 
          }
          return time.toFixed(2);
        }

        if(this.salary.times[index].start_time != null && this.salary.times[index].start_time != "" && this.salary.times[index].end_time != null && this.salary.times[index].end_time != ""){
          time = parseFloat(time) + (this.timeToDecimal(this.salary.times[index].end_time) - this.timeToDecimal(this.salary.times[index].start_time)) - this.calculateBreak(index) 
        }

        if(this.salary.times[index].holiday == true){
          time = parseFloat(time) + parseFloat(parseFloat(this.salary.base_time) / 26);
        }

        if(this.salary.times[index].ill == true){
          time = parseFloat(time) + parseFloat(parseFloat(this.salary.base_time) / 26);
        }

        if(this.salary.times[index].legal_holiday == true){
          time = parseFloat(time) + parseFloat(parseFloat(this.salary.base_time) / 26);
        }
        if(this.salary.times[index].shool_short == true){
          time = parseFloat(time) + 5;
        }
        if(this.salary.times[index].shool_long == true){
          time = parseFloat(time) + 8;
        }

        return time.toFixed(2);
      },

      calculateBreak(index, second = false){
        if(second == true)
        {
          if(this.salary.times[index].second_start_time != null && this.salary.times[index].second_start_time != "" && this.salary.times[index].second_end_time != null && this.salary.times[index].second_end_time != ""){
            if((this.timeToDecimal(this.salary.times[index].second_end_time) - this.timeToDecimal(this.salary.times[index].second_start_time)) > 6){
              return 0.5;
            }
            else{
              return 0;
            }
          }
          else{
            return 0;
          }
        }

        if(this.salary.times[index].start_time != null && this.salary.times[index].start_time != "" && this.salary.times[index].end_time != null && this.salary.times[index].end_time != ""){
            if((this.timeToDecimal(this.salary.times[index].end_time) - this.timeToDecimal(this.salary.times[index].start_time)) > 6){
              return 0.5;
            }
            else{
              return 0;
            }
        }
        else{
          return 0;
        }
      },

      updateSalary(){
        this.axios
        .put("/salary/" + this.salary.id, this.salary)
        .then(() => {
          // this.salary = response.data.data;
          // this.generateTable(month, year);
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
      },

      getSalary(){
        var month = parseInt(this.showDate.format('MM'));
        var year = parseInt(this.showDate.format('YYYY'));
        this.axios
        .post("/salary", {
          'year': year,
          'month': month,
          'employee_id': this.employee.id
        })
        .then((response) => {
          this.salary = response.data.data;
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
        });
      },

      timeToDecimal(t) {
          var arr = t.split(':');
          var dec = parseInt((arr[1]/6)*10, 10);

          return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
      },

      generateMonths(){
        var month = parseInt(this.currentDate.format('MM'));
        var year = parseInt(this.currentDate.format('YYYY'));
        var showMonth = parseInt(this.showDate.format('MM'));
        this.months = [];
        for(var i=1; i<=12; i++){
          var date = moment({
            year: year,
            month: i-1,
            day: 1
          });
          var klasse = "";
          if(moment().format('YYYY') <= this.currentDate.format('YYYY'))
          {
            if(moment().format('YYYY') < this.currentDate.format('YYYY'))
            {
              klasse += ' disabled';
            }
            if(i > month && (i-month) != 1){
              klasse += ' disabled';
            }
          }
          //|| (date2.add(1, 'month').format('MM') + showMonth) == 1
          if(date.format('MM') == showMonth){
            klasse += ' active';
          }
          this.months.push({
            year: year,
            name: date.locale('de').format('MMM'),
            klasse: klasse,
            month: i,
          });
        }
      },

      
			setShowDate(month, year) {
        if(this.hasChanges == true)
        {
          this.$swal({
              title: "Möchtest du die Änderungen wirklich verwerfen?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: `Ja, löschen!!!`,
              denyButtonText: `Abbrechen`,
          })
          .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.hasChanges = false;
                this.setShowDate(month, year);
              }
          });
        } else
        {
          var curMonth = parseInt(this.currentDate.format('MM'));
          if(moment().format('YYYY') <= this.currentDate.format('YYYY'))
          {
            if(moment().format('YYYY') < this.currentDate.format('YYYY'))
            {
              return;
            }
            if(curMonth >= month){
              this.showDate = moment({
                year: year,
                month: month-1,
                day: 1
              });
            }
            else{
              this.showDate = moment({
                year: year,
                month: month-1,
                day: 1
              });
            }
          } else
          {
            this.showDate = moment({
              year: year,
              month: month-1,
              day: 1
            });
          }
        }
			},
	},

    mounted(){
        this.generateMonths();
        this.getSalary();
    }
}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		color: #2c3e50;
		height: 67vh;
		width: 90vw;
		margin-left: auto;
		margin-right: auto;
	}

  /* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
	min-height: 6rem;
}
/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */
/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item {
	background-color: #007bff;
	border-color: #d7e7d7;
  color: #fff;
}
/* .theme-default .cv-item.birthday::before {
	content: "\1F382"; /* Birthday cake 
	margin-right: 0.5em;
} */
/* The following classes style the classes computed in myDateClasses and passed to the component's dateClasses prop. */
.theme-default .cv-day.ides {
	background-color: #ffe0e0;
}
.ides .cv-day-number::before {
	content: "\271D";
}
.cv-day.do-you-remember.the-21st .cv-day-number::after {
	content: "\1F30D\1F32C\1F525";
}
</style>